import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/img/newHome/CardImg1.png'
import _imports_1 from '@/assets/img/newHome/CardImg2.png'
import _imports_2 from '@/assets/img/newHome/CardImg3.png'


const _hoisted_1 = { class: "out" }
const _hoisted_2 = { class: "bg" }
const _hoisted_3 = { class: "text" }
const _hoisted_4 = { class: "entry_list" }
const _hoisted_5 = { class: "text" }
const _hoisted_6 = { class: "desc" }
const _hoisted_7 = { class: "card_list" }
const _hoisted_8 = { class: "card_title" }
const _hoisted_9 = { class: "card_titles" }
const _hoisted_10 = { class: "card_desc" }
const _hoisted_11 = { class: "card_titles" }
const _hoisted_12 = { class: "card_desc" }
const _hoisted_13 = { class: "card_title" }
const _hoisted_14 = { class: "card_desc" }
const _hoisted_15 = { class: "card_desc" }
const _hoisted_16 = { style: {"color":"#f14368"} }
const _hoisted_17 = { class: "card_desc" }
const _hoisted_18 = { style: {"color":"#f14368"} }
const _hoisted_19 = { class: "card_desc" }
const _hoisted_20 = { style: {"color":"#f14368"} }
const _hoisted_21 = { class: "card_title" }
const _hoisted_22 = { class: "card_titles" }
const _hoisted_23 = { class: "card_desc" }
const _hoisted_24 = { class: "card_titles" }
const _hoisted_25 = { class: "card_desc" }
const _hoisted_26 = { class: "card_title" }
const _hoisted_27 = { class: "card_titles" }
const _hoisted_28 = { class: "card_desc" }

import { lang } from '@/lang';
import { computed } from 'vue';

//词条

export default /*@__PURE__*/_defineComponent({
  __name: 'CompetitiveAdvantages',
  setup(__props) {

const EntryList = computed(() => [
  {
    imgUrl: require('@/assets/img/newHome/Advantagesbg1.png'),
    title: computed(() => lang.v.RCompetitiveAdvantagesPro1),
    content: computed(() => lang.v.RCompetitiveAdvantagesPro1_1),
    index: 1,
  },
  {
    imgUrl: require('@/assets/img/newHome/Advantagesbg2.png'),
    title: computed(() => lang.v.RCompetitiveAdvantagesPro2),
    content: computed(() => lang.v.RCompetitiveAdvantagesPro2_1),
    index: 2,
  },
  {
    imgUrl: require('@/assets/img/newHome/Advantagesbg3.png'),
    title: computed(() => lang.v.RCompetitiveAdvantagesPro3),
    content: computed(() => lang.v.RCompetitiveAdvantagesPro3_1),
    index: 3,
  },
]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(lang).v.REntryTitle), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(EntryList.value, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.index,
          class: "entry_item",
          style: _normalizeStyle({ 'background-image': `url(${item.imgUrl})` })
        }, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(item.title), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(item.content), 1)
        ], 4))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(lang).v.Rcard1), 1),
      _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(lang).v.RcardTitle1_1), 1),
      _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(lang).v.RcardDesc1_1), 1),
      _createElementVNode("div", _hoisted_11, _toDisplayString(_unref(lang).v.RcardTitle1_2), 1),
      _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(lang).v.RcardDesc1_2), 1),
      _cache[3] || (_cache[3] = _createElementVNode("img", {
        src: _imports_0,
        alt: "",
        style: {"width":"3.2rem","height":"3.2rem"}
      }, null, -1)),
      _createElementVNode("div", _hoisted_13, _toDisplayString(_unref(lang).v.Rcard2), 1),
      _createElementVNode("div", _hoisted_14, _toDisplayString(_unref(lang).v.RcardDesc2_1), 1),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("span", _hoisted_16, _toDisplayString(_unref(lang).v.RcardDesc2_2p), 1),
        _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
        _createTextVNode(" " + _toDisplayString(_unref(lang).v.RcardDesc2_2c), 1)
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("span", _hoisted_18, _toDisplayString(_unref(lang).v.RcardDesc2_3p), 1),
        _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
        _createTextVNode(" " + _toDisplayString(_unref(lang).v.RcardDesc2_3c), 1)
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("span", _hoisted_20, _toDisplayString(_unref(lang).v.RcardDesc2_4p), 1),
        _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
        _createTextVNode(" " + _toDisplayString(_unref(lang).v.RcardDesc2_4c), 1)
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("img", {
        src: _imports_1,
        alt: "",
        style: {"width":"3.2rem","height":"3.2rem"}
      }, null, -1)),
      _createElementVNode("div", _hoisted_21, _toDisplayString(_unref(lang).v.Rcard3), 1),
      _createElementVNode("div", _hoisted_22, _toDisplayString(_unref(lang).v.RcardTitle3_1), 1),
      _createElementVNode("div", _hoisted_23, _toDisplayString(_unref(lang).v.RcardDesc3_1), 1),
      _createElementVNode("div", _hoisted_24, _toDisplayString(_unref(lang).v.RcardTitle3_2), 1),
      _createElementVNode("div", _hoisted_25, _toDisplayString(_unref(lang).v.RcardDesc3_2), 1),
      _cache[5] || (_cache[5] = _createElementVNode("img", {
        src: _imports_2,
        alt: "",
        style: {"width":"3.2rem","height":"3.2rem"}
      }, null, -1)),
      _createElementVNode("div", _hoisted_26, _toDisplayString(_unref(lang).v.Rcard4), 1),
      _createElementVNode("div", _hoisted_27, _toDisplayString(_unref(lang).v.RcardTitle4_1), 1),
      _createElementVNode("div", _hoisted_28, _toDisplayString(_unref(lang).v.RcardDesc4_1), 1),
      _cache[6] || (_cache[6] = _createElementVNode("img", {
        src: _imports_1,
        alt: "",
        style: {"width":"3.2rem","height":"3.2rem"}
      }, null, -1))
    ])
  ]))
}
}

})