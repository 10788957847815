import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "market" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "title_text" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "desc" }
const _hoisted_6 = { class: "introduce" }
const _hoisted_7 = { class: "introduce" }
const _hoisted_8 = ["src"]

import { lang } from '@/lang';
import playsUrl from '@/assets/img/newHome/plays.png';
import marketBackUrl from '@/assets/img/newHome/marketBack.png';

export default /*@__PURE__*/_defineComponent({
  __name: 'MarketBackground',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString(_unref(lang).v.RMarketBackgroundPro), 1),
        _createElementVNode("img", {
          src: _unref(playsUrl),
          alt: "",
          class: "title_img"
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(lang).v.RMarket), 1),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_unref(lang).v.RMarketIntroduce), 1),
      _createElementVNode("p", _hoisted_7, [
        _createTextVNode(_toDisplayString(_unref(lang).v.RMarketIntroduce1), 1),
        _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
        _createTextVNode(_toDisplayString(_unref(lang).v.RTCCertificate), 1)
      ])
    ]),
    _createElementVNode("img", {
      src: _unref(marketBackUrl),
      alt: "",
      class: "img_market"
    }, null, 8, _hoisted_8)
  ], 64))
}
}

})